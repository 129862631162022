import { onAuthorised } from './onAuthorised';
import { storage } from '../storage';
import { ACTIONS, ACTION_USED } from '../../constants/paymentMethodsHub';

const response = {
  authorisedMessage: '',
};

const refreshPaymentMethodsCollectionMock = jest.fn();
const onAddPaymentMethodCloseMock = jest.fn();

const commonEmitMock = jest.fn();

const composablePayload = {
  contextData: {
    isRedirected: false,
    flowTracking: {
      currentAction: ACTIONS.add,
      addIsEmbedded: false,
    },
  },
  commonEmit: (param, value) => {
    commonEmitMock(param, value);
  },
  originalResponse: response,
  parentActionCallback: param => {
    switch (param) {
      case 'refreshPaymentMethodsCollection':
        refreshPaymentMethodsCollectionMock();
        break;

      case 'onAddPaymentMethodClose':
        onAddPaymentMethodCloseMock();
        break;

      default:
        break;
    }
  },
};

describe('When the "onAuthorised" method is called', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  afterEach(() => {
    jest.resetAllMocks();

    storage.remove(ACTION_USED);
  });

  it('Then the "refreshPaymentMethodsCollection" method is called', async () => {
    onAuthorised(composablePayload);

    expect(refreshPaymentMethodsCollectionMock).toHaveBeenCalled();
  });

  it('Then the "on:add-payment-method-authorised" event is emitted', () => {
    onAuthorised(composablePayload);

    expect(commonEmitMock).toHaveBeenCalledWith('on:add-payment-method-authorised', { authorisedMessage: '' });
  });

  describe('And the "addIsEmbedded" property of flowTracking is false', () => {
    it('Then the "onAddPaymentMethodClose" method is called', () => {
      composablePayload.contextData.flowTracking.addIsEmbedded = false;

      onAuthorised(composablePayload);

      expect(onAddPaymentMethodCloseMock).toHaveBeenCalled();
    });
  });

  describe('And the "addIsEmbedded" property of flowTracking is true', () => {
    it('Then the "onAddPaymentMethodClose" method is not called', () => {
      composablePayload.contextData.flowTracking.addIsEmbedded = true;

      onAuthorised(composablePayload);

      expect(onAddPaymentMethodCloseMock).not.toHaveBeenCalled();
    });
  });

  describe('And is being redirected', () => {
    composablePayload.contextData.isRedirected = true;

    storage.add(ACTION_USED, ACTIONS.add);

    it('Then the "on:payment-method-authorised" event is emitted', () => {
      composablePayload.contextData.flowTracking.currentAction = 'add';

      onAuthorised(composablePayload);

      expect(commonEmitMock).toHaveBeenCalledWith('on:add-payment-method-authorised', { authorisedMessage: '' });
    });

    it('Then the "onAddPaymentMethodClose" is not called', () => {
      composablePayload.contextData.flowTracking.currentAction = null;

      onAuthorised(composablePayload);

      expect(onAddPaymentMethodCloseMock).not.toHaveBeenCalled();
    });

    it('Then the "refreshPaymentMethodsCollection" is not called', () => {
      composablePayload.contextData.flowTracking.currentAction = null;

      onAuthorised(composablePayload);

      expect(refreshPaymentMethodsCollectionMock).not.toHaveBeenCalled();
    });
  });
});
